<template>
  <div id="app">
    <SiteHeader/>
    <MobileNavigation/>

    <Page>
      <transition
        name="fade"
        mode="out-in">
      <router-view :key="$route.fullPath"/>
      </transition>
    </Page>

  </div>
</template>

<script>
import SiteHeader from '@/components/header/SiteHeader'
import MobileNavigation from '@/components/header/MobileNavigation'
import Page from '@/components/ui/Page'

export default {
  name: 'App',
  components: {SiteHeader, MobileNavigation, Page}
}
</script>

<style lang="scss">
@import 'sass/app';

#app {
  @apply text-center;
  @apply min-h-screen;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }

}

#nav {
  padding: 30px;

  a {
    @apply font-bold;
    color: #212529;

    &.router-link-exact-active {
      color: #212529;
    }
  }
}
</style>
