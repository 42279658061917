import Vue from 'vue'

export default {
    loadNavigation () {
        const requestData = {
            'query': `{
              globals {
                navigation {
                  links {
                    ... on LinksLink {
                      linkTitle,
                      linkUrl
                    }
                  }
                }
              }
            }`
        }
        return Vue.axios.post('', requestData)
    }
}