import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import gallery from './gallery'
import header from './header'
import page from './page'

export default new Vuex.Store({
  modules: {
    gallery,
    header,
    page
  }
})
