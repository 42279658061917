<template>
  <nav>
    <div
        class="hamburger"
        :class="{active: showMobileNavigation || showGallery}"
        @click="toggleMobileNavigation">
      <div class="bun"></div>
      <div class="bun"></div>
    </div>

    <ul>
      <li
          v-for="(navItem, index) in navigationItems"
          :key="index">
        <router-link
            :class="{ active: navItem.linkUrl === $route.path }"
            :to="navItem.linkUrl">{{ navItem.linkTitle }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  name: 'Navigation',
  data () {
    return {}
  },
  computed: {
    ...mapState('header', {
      navigationItems: 'navigationItems',
      showMobileNavigation: 'showMobileNavigation'
    }),
    ...mapState('gallery', {
      showGallery: 'show'
    }),
  },
  methods: {
    ...mapActions('header', {
      toggleMobileNavigation: 'toggleMobileNavigation'
    }),
  }
}
</script>

<style lang="scss" scoped>
nav {
  @apply relative;
  @apply flex;
  @apply items-center;
  @apply w-full;
  max-width: 50px;

  .hamburger {
    @apply absolute;
    @apply right-0;
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @apply flex-col;
    @apply transition-all;
    @apply duration-300;
    @apply ease-in;
    width: 24px;
    height: 10px;

    &.active {
      height: 18px;
      right: -4px;

      .bun:nth-of-type(1) {
        transform: rotate(45deg);
      }
      .bun:nth-of-type(2) {
        transform: rotate(-45deg);
      }
    }

    .bun {
      @apply relative;
      @apply transition-all;
      @apply duration-300;
      @apply ease-in;
      @apply origin-left;
    }

    .bun {
      @apply w-full;
      @apply h-px;
      background-color: #303030;
    }
  }

  ul {
    @apply hidden;
  }

  /* Tablet */
  @media screen and (min-width: 768px) {
    @apply block;

    .hamburger {
      width: 31px;
      height: 13px;
      top: 4px;

      &.active {
        height: 23px;
        right: -4px;
        top: -1px;
      }
    }
  }

  /* Desktop */
  @media screen and (min-width: 1300px) {
    max-width: 777px;

    .hamburger {
      @apply hidden;
    }

    ul {
      @apply flex;
      @apply justify-between;
      @apply list-none;
      @apply text-center;
      margin: 0 40px;

      li {
        @apply transition-all;
        @apply duration-200;
        @apply ease-in-out;
        font-size: 14px;
        letter-spacing: 1.06px;
        line-height: 16px;

        a {
          @apply relative;
          @apply no-underline;
          font-family: 'UniversNext', serif;
          color: #303030 !important;
          padding: 6px 0;

          &:after {
            @apply absolute;
            @apply left-0;
            @apply block;
            @apply w-0;
            @apply h-px;
            bottom: 2px;
            content: "";
            color: #303030;
            background: #303030;
            transition: width .3s ease 0s, left .3s ease 0s;
          }

          &:hover:after, &.active:after {
            @apply w-full;
            //left: -2px;
          }
        }
      }
    }
  }
}
</style>