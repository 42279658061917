
const state = {
  showPage: false,
}

const getters = {
  //
}

const mutations = {
  setNavigationItems(state, navigationItems) {
    state.navigationItems = navigationItems
  },
  toggleShowPage(state) {
    state.showPage = !state.showPage
  }
}


const actions = {
  toggleShowPage({commit}) {
    commit('toggleShowPage')
  }
}


const modules = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules
}