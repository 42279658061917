import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/routes'
import store from './store/store'
import './assets/tailwind.css'

// Plugins
import axios from 'axios'
import VueAxios from 'vue-axios'
import PinchZoom from 'vue-pinch-zoom'
import Vue2TouchEvents from 'vue2-touch-events'
import vueVimeoPlayer from 'vue-vimeo-player'

let m = document.location.href.match(/\btoken=([^&]+)/);
const previewToken = m ? m[1] : '';
const requestPath = previewToken === '' ? '/api' : '/api?token=' + previewToken

// axios configuration
const axiosCustom = axios.create({
  baseURL: requestPath,
  headers: {
    'Authorization': `Bearer ${process.env.VUE_APP_AUTHORIZATION_TOKEN}`
  }
});

Vue.use(VueAxios, axiosCustom)
Vue.use(Vue2TouchEvents)
Vue.component('pinch-zoom', PinchZoom)
Vue.use(vueVimeoPlayer)


Vue.mixin({
  methods: {
    updatePageTitle(newTitle) {
      document.title = newTitle
    }
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
