import Contact from './contact'
import Footer from './footer'
import Header from './header'
import Portfolio from './portfolio'
import Video from './video'

export default {
    Contact,
    Footer,
    Header,
    Portfolio,
    Video
}