import api from '@/api'

const state = {
  showMobileNavigation: false,
  navigationItems: [],
}

const getters = {
  //
}

const mutations = {
  setNavigationItems(state, navigationItems) {
    localStorage.setItem('navigation', JSON.stringify(navigationItems))

    state.navigationItems = navigationItems
  },
  toggleShowMobileNavigation(state) {
    state.showMobileNavigation = !state.showMobileNavigation
  }
}


const actions = {
  loadNavigationItems({commit}) {
    return api.Header.loadNavigation().then(result => result.data).then(data => {
      if (data.errors !== undefined) {
        console.log('Failed to load navigation. Reason: ' + data.errors[0])
        return
      }

      // noinspection JSDeprecatedSymbols
      commit('setNavigationItems', data.data.globals.navigation.links)
    }).catch(() => {
      // When we get a server error, just serve up localStorage
      commit('setNavigationItems', JSON.parse(localStorage.getItem('navigation')))
    })
  },
  toggleMobileNavigation({commit}) {
    commit('toggleShowMobileNavigation')
  }
}


const modules = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules
}