<template>
  <header :class="{ show: show }">
    <router-link class="site-title" to="/">
      Nick Bayless
    </router-link>
    <Navigation/>
  </header>
</template>

<script>
import {mapActions} from 'vuex'
import Navigation from '@/components/header/Navigation'

export default {
  name: "SiteHeader",
  components: {Navigation},
  data() {
    return {
      show: false
    }
  },
  mounted() {
    this.loadNavigationItems().then(() => {
      this.show = true
    })
    // this.getScrollbarWidth()
  },
  methods: {
    ...mapActions('header', {
      loadNavigationItems: 'loadNavigationItems'
    }),
    // getScrollbarWidth() {
    //   // Creating invisible container
    //   const outer = document.createElement('div');
    //   outer.style.visibility = 'hidden';
    //   outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    //   outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
    //   document.body.appendChild(outer);
    //
    //   // Creating inner element and placing it in the container
    //   const inner = document.createElement('div');
    //   outer.appendChild(inner);
    //
    //   // Calculating difference between container's full width and the child width
    //   const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);
    //
    //   // Removing temporary elements from the DOM
    //   outer.parentNode.removeChild(outer);
    //   return scrollbarWidth;
    // }
  },

}
</script>

<style lang="scss" scoped>
header {
  @apply fixed;
  @apply top-0;
  @apply left-0;
  @apply flex;
  @apply justify-between;
  @apply w-full;
  z-index: 1;
  padding: 31px 24px;
  opacity: 0;
  transition: opacity 0.25s linear;

  &.show {
    opacity: 1;
  }

  .site-title {
    @apply no-underline;
    @apply uppercase;
    font-size: 18px;
    line-height: 14px;
    letter-spacing: 1.4px;
    color: #303030;
    font-family: 'UniversNext', serif;
  }

  /* Tablet */
  @media screen and (min-width: 768px) {
    padding: 33px 40px;
    font-size: 24px;

    .site-title {
      font-size: 24px;
      line-height: 20px;
    }
  }

  /* Desktop */
  @media screen and (min-width: 1300px) {
    padding: 44px 40px;

    .site-title {
      letter-spacing: 1.82px;
      line-height: 18px;
      margin-left: 40px;
    }
  }
}

</style>