import Vue from 'vue'

export default {
    loadVideo () {
        const requestData = {
            'query': `{
              entry(section: [video], search: "slug:video") {
                ... on Video {
                  seo{
                    title
                  }
                  title
                  showTitle
                  videoContainer {
                    ... on VideoContainerSuperTableBlockType {
                      id
                      fullWidthTile
                      imageAnimation {
                        url
                        title
                      }
                      vimeoVideoId
                    }
                  }
                }
              }
            }`
        }
        return Vue.axios.post('', requestData)
    }
}