import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/video',
    name: 'Video',
    // component: Video,
    component: () =>
      import(
        /* webpackChunkName: "video" */
        '../views/Video.vue'
      )
  },
  {
    path: '/contact',
    name: 'Contact',
    // component: Contact
    component: () =>
      import(
        /* webpackChunkName: "contact" */
        '../views/Contact.vue'
      )
  },
  {
    path: '/',
    name: 'Home',
    // component: Portfolio
    component: () =>
      import(
        /* webpackChunkName: "portfolio" */
        '../views/Portfolio.vue'
      )
  },
  {
    path: '/portfolio/:slug',
    name: 'AdminPortfolio',
    // component: Portfolio
    component: () =>
      import(
        /* webpackChunkName: "portfolio" */
        '../views/Portfolio.vue'
      )
  },
  {
    path: '/:slug',
    name: 'Portfolio',
    // component: Portfolio
    component: () =>
      import(
        /* webpackChunkName: "portfolio" */
        '../views/Portfolio.vue'
      )
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    setTimeout(() => {
      document.getElementById('page').scrollTop = 0;
    }, 500)
  }
})

export default router
