import Vue from 'vue'

export default {
    loadPortfolio (portfolioSlug) {
        const requestData = {
            'query': `{
              entry(section: [portfolio], search: "slug:${portfolioSlug}") {
                ... on Portfolio {
                  seo{
                    title
                  }
                  title
                  showTitle
                  masonryContainer {
                    ... on MasonryContainerMasonryRow {
                      dominant
                      tile {
                        id
                        tileImage {
                          url
                          title
                        }
                        galleryImage {
                          url
                          title
                        }
                        hiccup
                      }
                    }
                  }
                }
              }
            }`
        }
        return Vue.axios.post('', requestData)
    }
}