

const state = {
  show: false,
  activeId: null,
  itemIds: [],
  itemsToLoad: [],
  loadedItems: []
}

const getters = {
  currentSlide: state => {
    return state.itemIds.findIndex(id => id === state.activeId) + 1
  },
  totalSlides: state => {
    return state.itemIds.length
  },
  activeIndex: state => {
    return state.itemIds.findIndex(id => id === state.activeId)
  }
}

const mutations = {
  toggleShow(state) {
    state.show = !state.show
  },
  setActiveId(state, activeId) {
    state.activeId = activeId
  },
  setItemIds(state, itemIds) {
    state.itemIds = itemIds
  },
  setItemsToLoad(state, itemsToLoad) {
    state.itemsToLoad = itemsToLoad
  },
  appendLoadedItem(state, loadedItem) {
    state.loadedItems.push(loadedItem)
  }
}


const actions = {
  toggleGalleryShow({commit}) {
    commit('toggleShow')
  },
  activateGalleryId({commit}, activeId) {
    commit('setActiveId', activeId)
  },
  applyItemIds({commit}, items) {
    commit('setItemIds', items.map(item => item.id))
  },
  updateItemsToLoad({state, getters, commit}) {
    // First load the active index.
    let itemsToLoad = [state.itemIds[getters.activeIndex]]

    // Then set the first item to the right, first item to left, second item to right, and second item to left.
    const firstRightIndex = getters.activeIndex + 1 >= getters.totalSlides ? 0 : getters.activeIndex + 1
    itemsToLoad.push(state.itemIds[firstRightIndex])

    const firstLeftIndex = getters.activeIndex - 1 < 0 ? getters.totalSlides - 1 :  getters.activeIndex - 1
    itemsToLoad.push(state.itemIds[firstLeftIndex])

    let secondRightIndex = getters.activeIndex + 2
    secondRightIndex = secondRightIndex >= getters.totalSlides ? (secondRightIndex) - getters.totalSlides : secondRightIndex
    itemsToLoad.push(state.itemIds[secondRightIndex])

    let secondLeftIndex = getters.activeIndex - 2
    secondLeftIndex = secondLeftIndex < 0 ? getters.totalSlides + secondLeftIndex : secondLeftIndex
    itemsToLoad.push(state.itemIds[secondLeftIndex])

    // console.log(`currentIndex: ${getters.activeIndex} firstRightIndex: ${firstRightIndex} firstLeftIndex: ${firstLeftIndex} secondRightIndex: ${secondRightIndex} secondLeftIndex: ${secondLeftIndex}` )

    commit('setItemsToLoad', itemsToLoad)
  },
  nextSlide({state, getters, commit, dispatch}) {
    let nextIndex = getters.activeIndex + 1
    nextIndex = nextIndex >= state.itemIds.length ? 0 : nextIndex

    commit('setActiveId', state.itemIds[nextIndex])
    dispatch('updateItemsToLoad')
  },
  previousSlide({state, getters, commit, dispatch}) {
    let nextIndex = getters.activeIndex - 1
    nextIndex = nextIndex < 0 ? state.itemIds.length -1 : nextIndex

    commit('setActiveId', state.itemIds[nextIndex])
    dispatch('updateItemsToLoad')
  },
  setLoadedItem({commit}, itemId) {
    commit('appendLoadedItem', itemId)
  }
}


const modules = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules
}