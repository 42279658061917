<template>
  <nav :class="{ active: showMobileNavigation }">
    <ul>
      <li
          v-for="(navItem, index) in navigationItems"
          :key="index">
        <router-link
            :class="{ active: navItem.linkUrl === $route.path }"
            :to="navItem.linkUrl">{{ navItem.linkTitle }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  name: 'MobileNavigation',
  data () {
    return {}
  },
  computed: {
    ...mapState('header', {
      navigationItems: 'navigationItems',
      showMobileNavigation: 'showMobileNavigation'
    })
  },
  watch: {
    '$route' () {
      if (this.showMobileNavigation) {
        this.toggleMobileNavigation()
      }
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions('header', {
      toggleMobileNavigation: 'toggleMobileNavigation'
    }),
  }
}
</script>

<style lang="scss" scoped>
nav {
  @apply absolute;
  @apply flex;
  @apply h-0;
  @apply justify-center;
  @apply items-center;
  @apply overflow-hidden;
  @apply w-full;
  top: 75px;
  z-index: 100;
  transition: height 0.3s linear;

  &.active {
    height: calc(100% - 75px);
  }

  ul {
    li {
      @apply relative;
      @apply block;
      top: -20px;

      a {
        color: #303030!important;
        text-transform: uppercase;
        text-decoration: none!important;
        padding: 5px 0 2px;
        position: relative;
        font-size: 18px;
        letter-spacing: 1.4px;
        line-height: 44px;

        &:after {
          @apply absolute;
          @apply bottom-0;
          @apply left-0;
          @apply block;
          @apply w-0;
          @apply h-px;
          content: "";
          color: #303030;
          background: #303030;
          transition: width .3s ease 0s, left .3s ease 0s;
        }

        &:hover:after, &.active:after {
          @apply w-full;
          left: -2px;
        }
      }
    }
  }
}
</style>