import Vue from 'vue'

export default {
  loadContactPage() {
    const requestData = {
      'query': `{
        entry(section: [contact], search: "slug:contact") {
          ...on Contact {
            seo{
              title
            }
            title
            showTitle
            imageUpload {
              url
              title
            }
            links {
              ...on LinksLink {
                linkTitle
                linkUrl
                openNewWindow
              }
            }
            textarea
            largeTextarea
          }
        }
      }`,
    }
    return Vue.axios.post('', requestData)
  },
}