<template>
  <main id="page" class="page" :class="{ show: showPage }">
    <slot></slot>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Page',
  data() {
    return {
      show: false
    }
  },
  computed: {
    ...mapState('page', {
      showPage: 'showPage'
    }),
  },
  watch: {
    '$route' () {
      if (this.showPage) {
        // Toggle to false on route change.
        this.toggleShowPage()
      }
    }
  },
  methods: {
    ...mapActions('page', {
      toggleShowPage: 'toggleShowPage'
    })
  }
}
</script>

<style lang="scss" scoped>
  .page {
    @apply absolute;
    @apply bottom-0;
    @apply block;
    @apply overflow-auto;
    @apply w-full;
    height: calc(100% - 75px);
    padding: 0 24px;
    opacity: 0;
    transition: opacity 0.3s ease-out;

    &.show {
      transition: opacity 0.6s ease-in;
      opacity: 1;
    }

    @media screen and (min-width: 768px) {
      height: calc(100% - 106px);
      padding: 0 40px;
    }
  }
</style>